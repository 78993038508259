.ContactPage {
  text-align: center;
}

.ContactPage-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ContactPage-contact {
  background-color: #282c34;
  display: flex;
  min-width: 100vh;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  color: white;
}

.ContactPage-header-mobile {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ContactPage-contact-mobile {
  background-color: #282c34;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  color: white;
}
